import React from "react";
import GATSBY_COMPILED_MDX from "/opt/render/project/src/packages/website/content/blog/quill-1-0-release-candidate-released.mdx";
import {graphql} from "gatsby";
import Default from "../components/Default";
import SEO from "../components/SEO";
const Post = ({data, children}) => React.createElement(Default, {
  pageType: "blog"
}, React.createElement("div", {
  id: "blog-container",
  className: "container"
}, React.createElement("div", {
  className: "post"
}, React.createElement("h1", null, data.mdx?.frontmatter?.title), React.createElement("div", {
  className: "post-meta"
}, React.createElement("time", null, data.mdx.frontmatter.date), React.createElement("span", null, " - ", React.createElement("a", {
  href: "https://twitter.com/jhchen",
  title: "Jason Chen"
}, "Jason Chen"))), children)));
export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      fields {
        permalink
      }
      frontmatter {
        title
        date(formatString: "DD MMM yyyy")
      }
    }
  }
`;
export const Head = ({data}) => React.createElement(React.Fragment, null, React.createElement(SEO, {
  title: data.mdx.frontmatter.title,
  permalink: data.mdx.fields.permalink
}), React.createElement("link", {
  rel: "stylesheet",
  href: "/assets/css/base.css"
}), React.createElement("link", {
  rel: "stylesheet",
  href: "/assets/css/styles.css"
}));
Post
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Post, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
